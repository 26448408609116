<template>
    <div class="mychart bg-white p-2 ">
        <div class="d-flex bd-highlight border-bottom pt-2">
            <div class="flex-grow-1 bd-highlight chrttext">{{ $t('message.group_of_patient') }}<span class="charminitext"> </span></div>
            <div class="bd-highlight">
                <el-radio-group v-model="radio" size="mini">
                    <el-radio-button value="weekly" label="weekly">{{ $t('message.week') }}</el-radio-button>
                    <el-radio-button value="monthly" label="monthly">{{ $t('message.month') }}</el-radio-button>
                    <el-radio-button value="yearly" label="yearly">{{ $t('message.year') }}</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <crm-doughnut-chart :height="180" :data="dataChart1"></crm-doughnut-chart>
    </div>
</template>
<script>
    import CrmDoughnutChart from '@/views/charts/crm-doughnut-chart'
    import { mapActions } from "vuex";
    export default {
        components: {CrmDoughnutChart},
        data() {
            return {
                totalSum: 0,
                radio: 'weekly',
                dataChart: {},
                dataChart1: {
                    labels: ["от 25 до 40 лет",	"от 15 до 25 лет",	"от 05 до 15 лет",	"от 01 до 05 лет", "от 0  до 3 года", "от 0  до 3 года"],
                    datasets: [{
                        data: [320,	240, 149, 100, 59, 100],
                        borderColor: ['#2196f38c', '#f443368c', '#3f51b570', '#00968896', '#f1ae2f' ],
                        backgroundColor: ['#2196f38c', '#f443368c', '#3f51b570', '#00968896', '#f1ae2f' ],
                        borderWidth: 1
                    }]
                },
            }
        },
        watch: {
            radio: {
                handler: function () {
                    this.fetchData()
                }
            }
        },
        mounted(){
            this.fetchData()
        },
        methods:{
            ...mapActions({
                editChart : 'orders/chart'
            }),
            fetchData(){
                this.editChart(this.radio)
                    .then(res => {
                        if (res.chart_data){
                            this.totalSum = 0;
                            let labels = [], data = [];
                            for (let key in res.chart_data){
                                res.chart_data.sort((a, b) => b.date - a.date);
                                if (res.chart_data.hasOwnProperty(key)){
                                    let chart_data = res.chart_data[key];
                                    labels.push(chart_data.label);
                                    data.push(chart_data.sum)
                                    this.totalSum +=chart_data.sum;
                                }
                            }
                            this.dataChart = {
                                    labels: labels,
                                    datasets: [
                                    {
                                        label: 'Заказы',
                                        backgroundColor: '#f88c27',
                                        data: data
                                    }
                                ]
                            };
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        }
    }
</script>