<template>
    <div class="mychart bg-white p-2 ">
            <div class="d-flex bd-highlight border-bottom pt-2">
            <div class="flex-grow-1 bd-highlight chrttext">{{$t('message.orders')}}: <span class="charminitext"> {{ totalSum | formatMoney }}  </span></div>
            <div class="bd-highlight">
                <el-radio-group v-model="radio" size="mini">
                    <el-radio-button value="weekly" label="weekly">{{$t('message.week')}}</el-radio-button>
                    <el-radio-button value="monthly" label="monthly">{{$t('message.month')}}</el-radio-button>
                    <el-radio-button value="yearly" label="yearly">{{$t('message.year')}}</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <crm-bar-chart :height="180" :data="dataChart"></crm-bar-chart>
    </div>
</template>
<script>
    import CrmBarChart from '@/views/charts/crm-bar-chart'
    import { mapActions } from "vuex";
    export default {
        components: {CrmBarChart},
        data() {
            return {
                totalSum: 0,
                radio: 'weekly',
                dataChart: {}
            }
        },
        watch: {
            radio: {
                handler: function () {
                    this.fetchData()
                }
            }
        },
        mounted(){
            this.fetchData()
        },
        methods:{
            ...mapActions({
                editChart : 'orders/chart'
            }),
            fetchData(){
                this.editChart(this.radio)
                    .then(res => {
                        if (res.chart_data){
                            this.totalSum = 0;
                            let labels = [], data = [];
                            for (let key in res.chart_data){
                                res.chart_data.sort((a, b) => b.date - a.date);
                                if (res.chart_data.hasOwnProperty(key)){
                                    let chart_data = res.chart_data[key];
                                    labels.push(chart_data.label);
                                    data.push(chart_data.sum)
                                    this.totalSum +=chart_data.sum;
                                }
                            }
                            this.dataChart = {
                                    labels: labels,
                                    datasets: [
                                    {
                                        label: this.$t('message.expences'),
                                        backgroundColor: '#f88c27',
                                        data: data
                                    }
                                ]
                            };
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        }
    }
</script>